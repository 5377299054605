import React, { useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth, Cache } from "aws-amplify";
import { midway, parseJwt } from "./auth/midway";


const login = async () => {
  console.log("login...");
  const credentials = await midway.refresh();
  const user = await parseJwt(credentials.token).sub;
  try {
    console.log("checking current user...");
    const usr = await Auth.currentAuthenticatedUser();
    console.log("got current user!", usr);
    return usr;
  } catch (err) {
    console.log("signing in...");
    const usr = await Auth.federatedSignIn("midway-auth.amazon.com", credentials, user as any);
    console.log("signed in!", usr);
    return usr;
  }
};

export const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState("");

  console.log("rendering App");

  useEffect(() => {
    console.log("starting effect");
    login().then((data: any) => {
        console.log(data);
        setIsAuthenticated(true);
        setUser(data.username);
      })
      .catch((error) => {
        console.error(error);
        setIsAuthenticated(false);
      });
  }, []);

  if (!isAuthenticated) {
    return <>Authenticating...</>;
  }
  return <>User: {user}</>;
};
