import React from "react";
import { createRoot } from "react-dom/client";
import { Amplify } from "aws-amplify";
import { App } from "./App";
import { midway } from "./auth/midway";

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-2:7b9ff189-c497-41a9-b1e9-cd8b6eb4a7bc",
    region: "us-east-2",
    refreshHandlers: { "midway-auth.amazon.com": midway.refresh.bind(midway) },
  },
});

const container = document.getElementById("root");
if (container === null) {
  document.body.innerText = "missing #root element";
  throw new Error("missing #root element");
}
const root = createRoot(container);
root.render(<App />);
