import Cache from '@aws-amplify/cache';

const deleteQueryParameter = function (key: string) {
    const queryParams = new URLSearchParams(window.location.search);

    if (!queryParams.get(key)) {
        return;
    }
    queryParams.delete(key);
    const newUrl = new URL(window.location.href);

    newUrl.search = queryParams.toString();
    window.history.replaceState({}, '', newUrl.toString());
};

export const parseJwt = (token: string): { sub: string, exp: number } => {
    return JSON.parse(atob(token.split('.')[1]));
};

export const me = window.location.hostname + ":443";

export class Midway {

    public async refresh(audience = "cognito-identity.us-east-2.amazonaws.com:443") {
        const token = await this.getToken(audience);
        const parsedToken = parseJwt(token);

        return {
            token,
            expires_at: parsedToken.exp,
        };
    }

    private async getToken(audience: string) {
        const cachedFederatedInfo = Cache.getItem('federatedInfo');
        const currentTimestamp = Date.now();
        let midwayToken;
        const oneSecond = 1000;

        if (cachedFederatedInfo && currentTimestamp < cachedFederatedInfo.expires_at * oneSecond) {
            midwayToken = cachedFederatedInfo.token;
        } else {
            midwayToken = await this.getTokenOrRedirect(audience);
        }
        deleteQueryParameter('id_token');
        deleteQueryParameter('state');
        return midwayToken;
    }

    private async getTokenOrRedirect(audience: string): Promise<unknown> {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest()
            xhr.open('GET', this.buildSSOUrl(audience))
            xhr.withCredentials = true
            xhr.onload = () => {
                if (xhr.status === 200) {
                    resolve(xhr.responseText)
                } else {
                    window.location.href = this.buildRedirectUrl()
                }
            }
            // closes the promise in the case of an error
            xhr.onerror = reject
            xhr.send()
        })
    }

    private buildRedirectUrl(): string {
        const url = new URL("https://midway-auth.amazon.com/login?next=/SSO/redirect");
        Object.entries({
            client_id: window.location.hostname + ":443",
            redirect_uri: window.location.href,
            response_type: 'id_token',
            scope: 'openid',
            nonce: this.generateNonce(),
        }).forEach(([key, value]) => {
            url.searchParams.append(key, value);
        });

        return url.toString();
    }

    private buildSSOUrl(client_id: string): string {
        const url = new URL("https://midway-auth.amazon.com/SSO");
        Object.entries({
            response_type: 'id_token',
            client_id,
            redirect_uri: window.location.href,
            scope: 'openid',
            nonce: this.generateNonce(),
        }).forEach(([key, value]) => {
            url.searchParams.append(key, value);
        });

        return url.toString();
    }

    private generateNonce(): string {
        let nonce = ''
        const characterSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        for (let i = 0; i < 64; i += 1) {
            nonce += characterSet.charAt(Math.floor(Math.random() * characterSet.length))
        }
        return nonce
    }
}

export const midway = new Midway();